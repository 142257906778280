import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Card from "~/components/styled/card";
import ListSlider from "~/components/styled/ListSlider";
import Image from "~/components/image";
import { formatPrice } from "~/helpers/currency-formatter";

const TourList = ({ tours, gridCols }) => {
	return (
		<div className={`grid ${gridCols} gap-6`}>
			{tours.map((tour) => {
				return (
					<div className="mb-12">
						<Card key={tour.id}>
							<Link to={`/tours/${tour.slug}`} key={tour.id}>
								<Image
									alt="Tour Image"
									className="rounded-t-md transform border-gray-200	 border-b"
									image={tour.featuredImage}
								/>
								{/* <ListSlider slides={tour.slider} /> */}

								<div className="px-4 py-2 ">
									<div className="mb-0.5">
										<p className="font-medium capitalize truncate hover:text-primary">
											{tour.title}
										</p>
									</div>
									<div className="flex mb-4">
										{/* {node.destinations && node.destinations.map(destinations => {
                  return (
                  <div key={destinations.id}>
                  <p className="text-xs border rounded-full p-0.5 px-2 mr-1">{destinations.name}</p>
                  </div>
                )})} */}
										{tour.regions &&
											tour.regions.map((regions) => {
												return (
													<div key={regions.id}>
														<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
															{regions.name}
														</p>
													</div>
												);
											})}
										{tour.activity && (
											<p className="text-xs border rounded-full p-0.5 px-2">
												{tour.activity.name}
											</p>
										)}
									</div>
									<div>
										<p className="text-sm font-semibold self-end">
											From {tour.price && formatPrice(tour.price)}
										</p>
									</div>
									<div className="flex items-baseline">
										<div className="text-sm text-gray-500 lowercase">{tour.duration}</div>
										<div>
											{tour.quickfacts.lodging && (
												<span className="mx-1 text-sm text-gray-500"> | </span>
											)}
										</div>
										<div>
											{tour.quickfacts.lodging && (
												<span className="text-sm text-gray-500 lowercase">
													{tour.quickfacts.lodging} inns
												</span>
											)}
										</div>
									</div>
									<div className="flex items-baseline">
										{tour.quickfacts.level && (
											<div className="text-sm text-gray-500 lowercase">
												{tour.quickfacts.level.split("_").join(" to ")}
											</div>
										)}
									</div>
								</div>
							</Link>
						</Card>
					</div>
				);
			})}
		</div>
	);
};

TourList.propTypes = {
	tours: PropTypes.array,
	gridCols: PropTypes.string,
};

TourList.defaultProps = {
	gridCols: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
};

export default TourList;
