import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import TourList from "~/components/tour-list";
import Image from "~/components/image";
import { Link } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/outline";

const DestinationPage = ({ data }) => {
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);
	// const seo = {
	//   title: data.strapiDestination.name,
	// }
	//const description = `Discover the best of ${data.strapiDestination.name} on a bicycle tour.`
	// const language={
	//     href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.name}`,
	//   }

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title={`THE BEST ${data.strapiDestination.name} Family Adventures`}
				description={`Discover the best of ${data.strapiDestination.name} on our thoughtfully crafted ${data.strapiDestination.name} family adventures. We offer group and private departures on almost all our ${data.strapiDestination.name} family holidays.`}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.in/destinations/${data.strapiDestination.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/destinations/${data.strapiDestination.slug}`,
				// 	},
				// ]}
			/>
			<BreadcrumbJsonLd
				itemListElements={[
					{
						position: 1,
						name: "Destinations",
						item: "https://artofbicycletrips.com/destinations",
					},
					{
						position: 2,
						name: `${data.strapiDestination.name}`,
					},
				]}
			/>
			{data.strapiDestination.image && (
				<div className="">
					<Image
						className="rounded-lg shadow-md transform"
						image={data.strapiDestination.image}
						alt={data.strapiDestination.image.alternativeText}
						loading="eager"
					/>
				</div>
			)}

			{/* Breadcrumbs */}
			<div className="my-4 flex items-center capitalize whitespace-nowrap overflow-x-auto">
				<Link className="text-xs" to={`/`}>
					Home
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<Link className="text-xs" to={`/destinations`}>
					Destinations
				</Link>
				<ChevronRightIcon className="h-4 w-4 text-gray-300 mx-1" />
				<span className="text-xs">{data.strapiDestination.name}</span>
			</div>

			<div>
				<div className="text-center">
					<PageHeading>
						Active Adventures in {data.strapiDestination.name}
					</PageHeading>
				</div>
				<p className="leading-loose mb-20 font-light md:text-xl md:ml-32 md:mx-16 lg:mx-36">
					{data.strapiDestination.desc}
				</p>
			</div>
			<div>
				<h2 className="mb-10">
					Ease back into a {data.strapiDestination.name} adventure
				</h2>
				<TourList tours={flatTours} />
			</div>
		</Layout>
	);
};

export const query = graphql`
	query DestinationQuery($slug: String!) {
		allStrapiTour(
			filter: { destination: { slug: { eq: $slug } } }
			sort: { fields: price }
		) {
			edges {
				node {
					id
					duration
					price
					slug
					title
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					slider {
						id
						url
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.3)
							}
						}
					}

					quickfacts {
						lodging
						level
					}
					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}

		strapiDestination(slug: { eq: $slug }) {
			name
			desc
			slug
			image {
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(aspectRatio: 2.52, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`;

export default DestinationPage;
